<div
    (mouseleave)="hide.emit()"
    class="text-on-primary-500 absolute top-0 z-[1000] flex max-h-screen flex-col bg-primary-500 drop-shadow-2xl dark:bg-base-300 dark:text-base-content max-sm:h-screen sm:rounded-br-xl"
>
    <div
        class="flex flex-row flex-wrap items-center gap-1 p-1 max-sm:bg-primary-600 max-sm:dark:bg-base-300"
    >
        <button
            class="flex flex-row items-center gap-2 rounded-lg p-1.5 hover:bg-primary-800 dark:hover:bg-neutral"
            (click)="hide.emit()"
            [disabled]="!canInteract"
        >
            <div class="h-6 w-6">
                <mat-icon svgIcon="close" [inline]="true" />
            </div>
        </button>
        <a
            class="flex flex-row items-center gap-2 rounded-lg p-1.5 hover:bg-primary-800 dark:hover:bg-neutral"
            [style.pointerEvents]="canInteract ? 'auto' : 'none'"
            routerLink="/home"
            (click)="hide.emit()"
        >
            <div class="indicator h-6 w-6">
                <mat-icon svgIcon="home" [inline]="true" />
                @if (notificationCount != undefined) {
                    <span class="badge indicator-item badge-accent badge-xs">
                        {{ notificationCount }}
                    </span>
                }
            </div>
            <div>
                {{ 'common.home' | translate }}
            </div>
        </a>
        <!-- TODO: re-enable when documentation is ready -->
        <!-- <a
            class="flex flex-row items-center gap-2 rounded-lg p-1.5 hover:bg-primary-800 dark:hover:bg-neutral"
            [style.pointerEvents]="canInteract ? 'auto' : 'none'"
            [routerLink]="findPath"
            (click)="hide.emit()"
        >
            <div class="h-6 w-6">
                <mat-icon svgIcon="help-circle" [inline]="true" />
            </div>
            <div>
                {{ 'Explain This' | translate }}
            </div>
        </a> -->
        <a
            class="flex flex-row items-center gap-2 rounded-lg p-1.5 hover:bg-primary-800 dark:hover:bg-neutral"
            [style.pointerEvents]="canInteract ? 'auto' : 'none'"
            routerLink="/settings"
            (click)="hide.emit()"
        >
            <div class="h-6 w-6">
                <mat-icon svgIcon="cog" [inline]="true" />
            </div>
            <div>
                {{ 'setting.plural' | translate }}
            </div>
        </a>
        <button
            class="flex flex-row items-center gap-2 rounded-lg p-1.5 hover:bg-primary-800 dark:hover:bg-neutral"
            (click)="auth.signOut()"
            [disabled]="!canInteract"
        >
            <div class="h-6 w-6">
                <mat-icon svgIcon="logout" [inline]="true" />
            </div>
            <div>
                {{ 'auth.sign_out' | translate }}
            </div>
        </button>
    </div>
    <div
        class="flex w-screen flex-col gap-2 overflow-auto max-sm:pt-1 sm:w-fit"
        [style]="'--scrollbar-foreground: #ffffff40'"
    >
        @for (section of sections; track section.id) {
            <div class="flex flex-col">
                <div class="ml-3 flex flex-row items-center">
                    <div class="rounded-md text-sm">
                        {{ section.title | translate }}
                    </div>
                    <div
                        class="mx-6 h-[1px] grow rounded-full bg-base-100/25 dark:bg-base-100"
                    ></div>
                </div>
                <div
                    class="grid grid-cols-1 justify-items-center gap-2 p-2 sm:ml-3 sm:grid-cols-3"
                >
                    @for (item of section.items; track item.name) {
                        @if (item.path) {
                            <a
                                class="flex w-[250px] cursor-pointer flex-col gap-1 overflow-clip rounded-xl bg-primary-500 p-2 pl-3 hover:bg-primary-800 dark:bg-base-300 dark:hover:bg-neutral sm:w-[200px]"
                                [routerLink]="[item.path]"
                                (click)="hide.emit()"
                            >
                                <div
                                    class="flex h-full flex-row items-center gap-3"
                                >
                                    <div class="h-6 w-6">
                                        <mat-icon
                                            [svgIcon]="item.icon"
                                            [inline]="true"
                                        />
                                    </div>
                                    <div class="flex flex-col gap-0.5">
                                        <div
                                            class="font font-title text-base leading-none"
                                        >
                                            {{ item.name | translate }}
                                        </div>
                                        <div class="text-xs leading-none">
                                            {{ item.description | translate }}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        } @else {
                            <a
                                class="flex w-[250px] cursor-pointer flex-col gap-1 overflow-clip rounded-xl bg-primary-500 p-2 pl-3 hover:bg-primary-800 dark:bg-base-300 dark:hover:bg-neutral sm:w-[200px]"
                                [href]="[item.externalPath]"
                                (click)="hide.emit()"
                            >
                                <div
                                    class="flex h-full flex-row items-center gap-3"
                                >
                                    <div class="h-6 w-6">
                                        <mat-icon
                                            [svgIcon]="item.icon"
                                            [inline]="true"
                                        />
                                    </div>
                                    <div class="flex flex-col gap-0.5">
                                        <div
                                            class="font font-title text-base leading-none"
                                        >
                                            {{ item.name | translate }}
                                        </div>
                                        <div class="text-xs leading-none">
                                            {{ item.description | translate }}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        }
                    }
                </div>
            </div>
        }
    </div>
</div>
