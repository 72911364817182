<div class="mat-typography flex flex-col items-center">
    <mat-card appearance="outlined" class="m-4">
        <div class="flex w-80 flex-col items-center gap-6 p-3 text-center">
            <div class="font-bold">
                {{ 'password.reset.success.title' | translate }}
            </div>
            <div>
                {{ 'password.reset.success.description' | translate }}
            </div>
            <sz-button
                size="large"
                text="bad_route.go_home"
                bgColor="base"
                link="/home"
            />
        </div>
    </mat-card>
</div>
