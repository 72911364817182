import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { getAccessToken, getUserEmail } from '../shared/auth-utils';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        if (request.url.startsWith(environment.apiUrl)) {
            const token =
                request.headers.get('x-access-token') ?? getAccessToken();
            if (token) {
                request = request.clone({
                    setHeaders: {
                        'x-access-token': token,
                        'x-key': getUserEmail(),
                    },
                });
            }
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 403) {
                    this.router.navigate(['/not-auth']);
                }
                return throwError(error);
            }),
        );
    }
}
