import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    BadRouteComponent,
    BadRouteKind,
} from './bad-route/bad-route.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordSuccessComponent } from './reset-password-success/reset-password-success.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RootComponent } from './root/root.component';
import { SettingsComponent } from './settings/settings/settings.component';
import { AuthGuard } from './shared/auth.guard';
import { RoleGuard } from './shared/role.guard';
import { log } from './shared/log';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    {
        path: 'reset-password-success',
        component: ResetPasswordSuccessComponent,
    },
    {
        path: 'not-auth',
        component: BadRouteComponent,
        data: { kind: BadRouteKind.NotAuth },
    },
    {
        path: '',
        component: RootComponent,
        canActivate: [AuthGuard, RoleGuard],
        children: [
            {
                path: 'login',
                loadChildren: () =>
                    import('./login/login.module').then((m) => m.LoginModule),
            },
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'settings', component: SettingsComponent },
            {
                path: 'home',
                component: HomeComponent,
                loadChildren: () =>
                    import('./home/home.module').then((m) => m.HomeModule),
            },
            {
                path: 'announcements',
                loadChildren: () =>
                    import('./announcements/announcements.module').then(
                        (m) => m.AnnouncementsModule,
                    ),
            },
            {
                path: 'users',
                loadChildren: () =>
                    import('./users/users.module').then((m) => m.UsersModule),
            },
            {
                path: 'organizations',
                loadChildren: () =>
                    import('./organizations/organizations.module').then(
                        (m) => m.OrganizationsModule,
                    ),
            },
            {
                path: 'dashboards',
                loadChildren: () => {
                    log.tag('app-routing').info('import DashboardsModule');
                    return import('./dashboards/dashboards.module').then(
                        (m) => m.DashboardsModule,
                    );
                },
            },
            {
                path: 'devices',
                loadChildren: () =>
                    import('./devices/devices.module').then(
                        (m) => m.DevicesModule,
                    ),
            },
            {
                path: 'widgets',
                loadChildren: () =>
                    import('./custom-widgets/custom-widgets.module').then(
                        (m) => m.CustomWidgetsModule,
                    ),
            },
            {
                path: 'models',
                loadChildren: () =>
                    import('./models/models.module').then(
                        (m) => m.ModelsModule,
                    ),
            },
            {
                path: 'geo-fences',
                loadChildren: () =>
                    import('./geo-fences/geo-fences.module').then(
                        (m) => m.GeoFencesModule,
                    ),
            },
            {
                path: 'alerts',
                loadChildren: () =>
                    import('./alerts/alerts.module').then(
                        (m) => m.AlertsModule,
                    ),
            },
            {
                path: 'about',
                loadChildren: () =>
                    import('./about/about.module').then((m) => m.AboutModule),
            },
            {
                path: 'data-files',
                loadChildren: () =>
                    import('./data-files/data-files.module').then(
                        (m) => m.DataFilesModule,
                    ),
            },
            // TODO: remove this when data-files is ready for production
            {
                path: 'data-files-alt',
                loadChildren: () =>
                    import('./data-files-alt/data-files-root.module').then(
                        (m) => m.DataFilesRootModule,
                    ),
            },
            {
                path: 'reports',
                loadChildren: () =>
                    import('./reports/reports.module').then(
                        (m) => m.ReportsModule,
                    ),
            },
            {
                path: 'billing',
                loadChildren: () =>
                    import('./billing/billing.module').then(
                        (m) => m.BillingModule,
                    ),
            },
            {
                path: 'page-views',
                loadChildren: () =>
                    import('./page-views/page-views.module').then(
                        (m) => m.PageViewsModule,
                    ),
            },
            {
                path: 'device-simulators',
                loadChildren: () =>
                    import('./device-simulators/device-simulators.module').then(
                        (m) => m.DeviceSimulatorsModule,
                    ),
            },
            {
                path: 'about/roadmap',
                loadChildren: () =>
                    import('./roadmap/roadmap.module').then(
                        (m) => m.RoadmapModule,
                    ),
            },
            {
                path: 'ui-demo',
                loadChildren: () =>
                    import('./ui-demo/ui-demo.module').then(
                        (m) => m.UiDemoModule,
                    ),
            },
        ],
        runGuardsAndResolvers: 'always',
    },
    {
        path: '**',
        component: BadRouteComponent,
        data: { kind: BadRouteKind.NotFound },
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            bindToComponentInputs: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
