import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { RouteMetadataService } from '../services/route-metadata/route-metadata.service';
import { hasRole } from './auth-utils';
import { isDefined } from './common';

@Injectable({ providedIn: 'root' })
export class RoleGuard {
    constructor(
        private router: Router,
        private routeMetadata: RouteMetadataService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const role = this.routeMetadata.getHasRole(state.url);
        if (isDefined(role) && !hasRole(role)) {
            this.router.navigate(['/not-auth']);
            return false;
        }
        return true;
    }
}
