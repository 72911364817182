<nav
    class="absolute flex h-[44px] w-full flex-row items-center bg-gray-500 uppercase text-white"
>
    <div
        class="pointer-events-none relative flex h-full w-[20rem] flex-row items-center bg-orange-600 py-1 px-2 text-xl"
    >
        <img
            [src]="platformConfigurationService.favicon"
            [alt]="platformConfigurationService.logoAlt"
            width="36px"
        />
        <span class="font-bold">{{
            platformConfigurationService.getName('bullet')
        }}</span>
    </div>
</nav>
