import { Inject, Injectable, LOCALE_ID, Optional } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { DateTimeService } from './date-time.service';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    constructor(
        private dateTimeService: DateTimeService,
        @Optional() @Inject(LOCALE_ID) localeId,
    ) {
        super(localeId);
    }

    public format(date: Date): string {
        return this.dateTimeService.dateString(date);
    }
}
