import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavSection, getAccessibleSections } from './data';

@Component({ selector: 'app-nav-menu', templateUrl: 'nav-menu.component.html' })
export class NavMenuComponent implements OnInit {
    @Input() canInteract = true;
    @Output() hide = new EventEmitter();

    sections: NavSection[] = [];
    notificationCount;

    constructor(
        private router: Router,
        protected auth: AuthService,
        private api: ApiService,
    ) {}

    ngOnInit() {
        this.sections = getAccessibleSections();

        this.api.announcements
            .listen({ unread: true, isActive: true })
            .subscribe((unread) => {
                this.notificationCount =
                    unread.length > 0 ? unread.length : undefined;
            });
    }
}
