<div
    id="nav-bar"
    class="text-on-primary-500 relative z-[999] flex h-11 flex-row items-center justify-between bg-primary-500 p-1 dark:bg-base-300 dark:text-base-content"
>
    @if (!showMenu) {
        <button
            class="flex h-9 items-center gap-1 truncate"
            (click)="openMenu()"
            (mouseenter)="openMenu(true)"
        >
            <div class="indicator m-1.5 min-w-[24px]">
                <mat-icon [svgIcon]="icon" />
                @if (hasUnreadAnnouncements) {
                    <span
                        class="badge indicator-item badge-accent badge-xs"
                    ></span>
                }
            </div>
            <span class="truncate font-title text-xl font-medium">
                {{ navBar.overrideTitle ?? title | translate }}
            </span>
        </button>
        <div class="flex flex-row items-center gap-1">
            @for (icon of navBar.icons; track icon.icon) {
                @if (icon.show ? icon.show() : true) {
                    <div class="h-9 p-1.5 rounded-lg">
                        <mat-icon
                            class="text-on-primary-500 dark:text-white"
                            [svgIcon]="icon.icon"
                            [ngClass]="{
                                'animate-spin': icon.animation === 'spin',
                                'animate-pulse': icon.animation === 'pulse',
                                'animate-ping': icon.animation === 'ping',
                                'animate-bounce': icon.animation === 'bounce'
                            }"
                            [title]="icon.label | translate"
                        ></mat-icon>
                    </div>
                }
            }
            @if (navBar.icons.length > 0 && navBar.buttons.length > 0) {
                <div
                    class="w-[1px] h-4 mx-1 rounded-full bg-white opacity-80"
                ></div>
            }
            @for (button of navBar.buttons; track button.text) {
                @if (button.show ? button.show() : true) {
                    <button
                        class="h-9 p-1.5 rounded-lg hover:bg-white/20 text-on-primary-500"
                        [title]="button.text | translate"
                        (click)="button.onClick ? button.onClick() : null"
                    >
                        <mat-icon [svgIcon]="button.icon" />
                    </button>
                }
            }
            @if (navBar.canSearch) {
                <button
                    *ngIf="!showSearch"
                    id="search-button"
                    class="h-9 p-1.5 rounded-lg hover:bg-white/20"
                    (click)="openSearch()"
                >
                    <mat-icon svgIcon="magnify" />
                </button>
                @if (showSearch) {
                    <input
                        #searchInput
                        [ngClass]="inputClasses"
                        placeholder="{{ 'common.search' | translate }}"
                        [(ngModel)]="searchValue"
                        (blur)="inputBlurred()"
                    />
                }
                <button
                    *ngIf="showSearch"
                    id="close-button"
                    class="h-9 p-1.5 rounded-lg hover:bg-white/20"
                    (click)="clearSearch()"
                >
                    <mat-icon svgIcon="close" />
                </button>
            }
        </div>
    }
</div>
<app-nav-menu
    [class.invisible]="!showMenu"
    [canInteract]="canInteractWithMenu"
    (hide)="closeMenu()"
/>
