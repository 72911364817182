<h1 mat-dialog-title>{{ 'page_view.tracking' | translate }}</h1>
<div mat-dialog-content>
    <div>
        {{
            'settings.identified_tracking.description.long'
                | translate: { platformName: platformConfigurationService.name }
        }}
    </div>
    <sz-dialog-button-bar
        primaryText="settings.identified_tracking.personalized"
        secondaryText="settings.identified_tracking.anonymous"
        (primaryClick)="setIdentifiedTracking(true)"
        (secondaryClick)="setIdentifiedTracking(false)"
    />
</div>
