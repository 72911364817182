import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '../common/common.module';
import { AppMaterialModule } from '../services/app-material/app-material.module';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';

@NgModule({
    imports: [AppMaterialModule, CommonModule, RouterModule],
    exports: [NavBarComponent, NavMenuComponent],
    declarations: [NavBarComponent, NavMenuComponent],
})
export class NavBarModule {}
