import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class SearchService {
    private rawCurrentTerm$ = new BehaviorSubject<string>('');
    public currentTerm$ = this.rawCurrentTerm$.pipe(debounceTime(500));
    public notify = (term: string) => {
        if (term != this.currentTerm) {
            this.rawCurrentTerm$.next(term);
        }
    };
    public get currentTerm() {
        return this.rawCurrentTerm$.value;
    }
}
