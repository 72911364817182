import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
    constructor(private router: Router, private auth: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.auth.loggedIn) {
            return true;
        } else {
            // Not logged in so redirect to login page with the return url
            this.router.navigate(['/login'], {
                queryParams: { goTo: state.url },
            });
            return false;
        }
    }
}
