import {
    Component,
    DestroyRef,
    ElementRef,
    OnInit,
    ViewChild,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { NavBarService } from 'src/app/services/nav-bar/nav-bar.service';
import { RouteMetadataService } from 'src/app/services/route-metadata/route-metadata.service';
import { AppService } from '../../app.service';
import { ApiService } from '../../services/api/api.service';
import { SearchService } from '../../services/search/search.service';
import { clone } from '../../shared/common';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
})
export class NavBarComponent implements OnInit {
    icon: string;
    title = '';
    hasUnreadAnnouncements = false;
    allowHoverMenuTriggerTimestamp = 0;
    showMenu = false;
    canInteractWithMenu = false;
    showSearch = false;
    _searchValue = '';
    showSaveIcon = false;

    @ViewChild('searchInput') searchElement: ElementRef;
    destroyRef = inject(DestroyRef);

    get inputClasses(): string {
        let classes =
            'rounded-md border-0 outline-none ' +
            'text-sm text-white bg-white/20 placeholder:text-on-primary-500 ';
        if (this.showSearch) {
            classes += 'h-7 w-24 sm:w-36 my-1 mx-1 py-0 px-2 ';
        } else {
            classes += 'w-0 m-0 p-0 ';
        }
        return classes;
    }

    get searchValue() {
        return this._searchValue;
    }

    set searchValue(value) {
        if (value == this._searchValue) {
            return;
        }
        this._searchValue = value;
        this.searchService.notify(value);
    }

    constructor(
        public appService: AppService,
        public navBar: NavBarService,
        private activatedRoute: ActivatedRoute,
        private api: ApiService,
        private routeMetadata: RouteMetadataService,
        private router: Router,
        private searchService: SearchService,
        private titleService: Title,
        private translate: TranslateService,
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams
            ?.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((params) => {
                const term = params['search'] ?? '';
                if (term != this.searchValue) {
                    this.searchValue = term;
                    this.showSearch = term != '';
                }
            });

        this.searchService.currentTerm$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((term) => {
                const queryParams = clone(
                    this.activatedRoute.snapshot.queryParams,
                );
                queryParams['search'] = term == '' ? undefined : term;
                this.router.navigate([], { queryParams, replaceUrl: false });
            });

        let isFirstEvent = true;
        this.router.events
            .pipe(
                filter((e) => isFirstEvent || e instanceof NavigationEnd),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(async () => {
                isFirstEvent = false;
                const url = this.router.url;
                const title = await this.routeMetadata.getTitle(url);
                const translation = await this.translate.get(title).toPromise();
                // We need this check because the router URL might have changed
                // while `getTitle` was running.
                if (url == this.router.url) {
                    this.title = translation;
                    this.titleService.setTitle(this.title);
                    this.icon = this.routeMetadata.getIcon(this.router.url);
                }
            });

        this.api.announcements
            .listen({ unread: true, isActive: true })
            .subscribe((unread) => {
                this.hasUnreadAnnouncements = unread.length > 0;
            });
    }

    openMenu(hover = false) {
        const now = +new Date();
        if (hover && now < this.allowHoverMenuTriggerTimestamp) {
            // This prevents the menu from immediately opening again when the
            // mouse is over the menu button.
            return;
        }
        this.showMenu = true;
        // Let the user interact with the nav menu after a few ms
        // This should prevent the user from being redirected to home immediately
        setTimeout(() => (this.canInteractWithMenu = true), 100);
    }

    closeMenu() {
        this.allowHoverMenuTriggerTimestamp = +new Date() + 100;
        this.canInteractWithMenu = false;
        this.showMenu = false;
    }

    openSearch() {
        this.showSearch = true;
        // Focus the search input after it is shown.
        setTimeout(() => this.searchElement.nativeElement.focus(), 0);
    }

    clearSearch() {
        this.showSearch = false;
        this.searchValue = '';
    }

    inputBlurred() {
        if (this.searchValue == '') {
            this.showSearch = false;
        }
    }
}
