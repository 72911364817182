import { OverlayContainer } from '@angular/cdk/overlay';
import {
    Component,
    DestroyRef,
    OnDestroy,
    OnInit,
    inject,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { ApiService } from '../services/api/api.service';
import { NavBarService } from '../services/nav-bar/nav-bar.service';
import { SettingsService } from '../services/user/settings.service';
import { VersionService } from '../services/version/version.service';
import { isEmpty } from '../shared/common';
import { log } from '../shared/log';
import { getFromStorage, setToStorage } from '../shared/storage-utils';
import { PageViewService } from './../services/page-view/page-view.service';
import { DialogService } from '../services/dialog/dialog.service';
import { IdentifiedTrackingDialog } from '../dialogs/identified-tracking-dialog/identified-tracking-dialog.component';

@Component({ templateUrl: './root.component.html' })
export class RootComponent implements OnInit, OnDestroy {
    navEventsSubscription;
    firstNavEventSubscription;
    dialog = inject(DialogService);
    destroyRef = inject(DestroyRef);

    constructor(
        public overlayContainer: OverlayContainer,
        public settings: SettingsService,
        public versionService: VersionService,
        private api: ApiService,
        private pageViewService: PageViewService,
        private router: Router,
        private navBar: NavBarService,
    ) {
        log.tag('RootComponent').info('create');
    }

    async ngOnInit() {
        if (!this.settings.isLoaded) {
            let settings = {};
            try {
                const rawSettings = getFromStorage('settings');
                if (rawSettings) {
                    settings = JSON.parse(rawSettings);
                }
            } catch (e) {
                console.error('Error parsing settings from local storage.');
            }
            if (isEmpty(settings)) {
                settings = await this.api.settings.all();
                setToStorage('settings', JSON.stringify(settings));
            }
            await this.settings.init(settings);
            if (settings['allowIdentifiedTracking'] == null) {
                await this.dialog.open(IdentifiedTrackingDialog, {
                    disableClose: true,
                });
            }
        }
        this.versionService.loadHistory();

        this.navEventsSubscription = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                filter((event: NavigationStart) => {
                    const path = new URL(location.origin + event.url).pathname;
                    // Check if we're navigating to a new page.
                    // We don't want to record a page view when we're navigating
                    // to the existing page.
                    return location.pathname != path;
                }),
            )
            .subscribe((event: NavigationStart) => {
                this.pageViewService.addPageView(event.url);
                this.navBar.clear();
            });
        // This will only trigger one time when accessing any page through a URL.
        this.firstNavEventSubscription = this.router.events
            .pipe(first())
            .subscribe((event) =>
                this.pageViewService.addPageView(
                    event['url'] ?? event['routerEvent']['url'],
                ),
            );
    }

    ngOnDestroy(): void {
        if (this.navEventsSubscription) {
            this.navEventsSubscription.unsubscribe();
        }
        if (this.firstNavEventSubscription) {
            this.firstNavEventSubscription.unsubscribe();
        }
    }
}
