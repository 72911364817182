import { Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlatformConfigurationService } from 'src/app/services/platform-configuration.service';
import { SettingsService } from 'src/app/services/user/settings.service';

@Component({ templateUrl: './identified-tracking-dialog.component.html' })
export class IdentifiedTrackingDialog {
    platformConfigurationService = inject(PlatformConfigurationService);
    constructor(
        public settings: SettingsService,
        public dialogRef: MatDialogRef<IdentifiedTrackingDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    setIdentifiedTracking(allowIdentifiedTracking: boolean) {
        this.settings.set(
            'account.identified-tracking',
            allowIdentifiedTracking,
        );
        this.dialogRef.close();
    }
}
